import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="J" transform="translate(37.000000, 35.000000)">
      <path
          d="M 12.75 23.95 L 12.75 0 L 17.5 0 L 17.5 23.45 A 24.6 24.6 0 0 1 17.384 25.901 Q 17.216 27.584 16.8 28.95 Q 16.1 31.25 14.8 32.725 Q 13.5 34.2 11.7 34.9 Q 9.9 35.6 7.7 35.6 A 11.721 11.721 0 0 1 3.661 34.909 A 11.202 11.202 0 0 1 3.375 34.8 A 11.414 11.414 0 0 1 1.414 33.809 A 9.092 9.092 0 0 1 0 32.7 L 2.3 29.05 A 8.429 8.429 0 0 0 4.335 30.608 A 9.715 9.715 0 0 0 4.7 30.8 A 6.557 6.557 0 0 0 6.637 31.43 A 5.971 5.971 0 0 0 7.55 31.5 A 5.462 5.462 0 0 0 9.272 31.241 A 4.467 4.467 0 0 0 11.35 29.8 A 4.814 4.814 0 0 0 12.118 28.421 Q 12.635 27.043 12.729 24.924 A 22.055 22.055 0 0 0 12.75 23.95 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
